<template>
  <div class="dealsheet">
    <Loader
      v-if="loaderEnabled"
      :logo="loaderLogo"
    ></Loader>
    <KTCodePreview v-bind:title="title">
      <template v-slot:toolbar>
        <template>
          <div>
            <b-modal
              hide-footer
              id="modal-scrollable modal-lg"
              size="lg"
              class="text-center"
              ref="modal-scrollable"
              scrollable
              title="Danh sách dealsheet sắp hết hạn"
            >
              <p class="my-4">
                <b-table
                  bordered
                  hover
                  :items="listItemExpire"
                  :fields="fieldExpire"
                  :per-page="10"
                  :current-page="currentPageExpire"
                >
                  <template #cell(index)="row">
                    {{ row.item.index }}
                  </template>
                  <template #cell(targetMin)="row">
                    {{ row.item.targetMin }}%
                  </template>
                  <template #cell(achievedRatioOfAlias)="row">
                    <span class="text-danger">{{ row.item.achievedRatioOfAlias || 0 }}%</span>
                  </template>
                  <template #cell(priority)="row">
                    {{ row.item.priority }} ({{ checkPriority(row.item.priority) }})
                  </template>
                </b-table>
                <b-row>
                  <b-col fixed>
                    <p
                      class="mt-3 text-dark"
                      style="font-weight: 500"
                    >
                      Tổng số:
                      {{ listItemExpire.length }}
                    </p>
                  </b-col>
                  <b-pagination
                    class="custom-pagination"
                    v-show="listItemExpire.length > 10"
                    v-model="currentPageExpire"
                    :total-rows="listItemExpire.length"
                    :per-page="10"
                    align="right"
                  >
                  </b-pagination>
                </b-row>
              </p>
              <b-button
                style="width: 80px"
                variant="secondary"
                size="sm"
                @click="hideModal()"
              >
                <strong>Đóng</strong>
              </b-button>
            </b-modal>
          </div>
        </template>
        <div class="row">
          <div>
            <b-dropdown
              size="sm"
              id="dropdown-1"
              right
            >
              <template slot="button-content">
                <i
                  style="font-size: 1rem"
                  class="fas fa-cog"
                ></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item
                v-if="checkPermission('DEALSHEET_INSERT')"
                @click="importExcel"
              >
                <span>
                  <i
                    style="font-size: 1rem"
                    class="flaticon2-download-2"
                  ></i>
                  &nbsp; Nhập Excel</span>
              </b-dropdown-item>
              <b-dropdown-item @click="downloadExcel">
                <span>
                  <i
                    style="font-size: 1rem"
                    class="flaticon-file-2"
                  ></i>
                  &nbsp; Xuất Excel</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="ml-3">
            <router-link
              to="/dealsheets/info-deal-sheet"
              v-if="checkPermission('DEALSHEET_INSERT')"
            >
              <button
                type="button"
                class="btn btn-primary font-weight-bolder btn-sm"
              >
                <i
                  style="font-size: 1rem"
                  class="flaticon2-add-1"
                ></i>Thêm
                mới
              </button>
            </router-link>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-4">
          <b-col
            cols="4"
            md="4"
          >
            <b-input
              size="sm"
              placeholder="Tìm kiếm..."
              v-model="apiParams.searchText"
              @keyup="filterDebounce"
            ></b-input>
          </b-col>
          <b-col>
            <b-input-group>
              <date-picker
                placeholder="Ngày bắt đầu"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="apiParams.startDate"
              ></date-picker>
            </b-input-group>
          </b-col>
          <b-col>
            <b-input-group>
              <date-picker
                placeholder="Ngày kết thúc"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="apiParams.endDate"
              ></date-picker>
            </b-input-group>
          </b-col>
          <b-col>
            <b-form-select
              @change="onFilter"
              class="select-style"
              size="sm"
              v-model="apiParams.isActive"
              :options="listStatus"
              value-field="id"
              text-field="name"
            >
              <template v-slot:first>
                <b-form-select-option :value="null">--Trạng thái--</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col>
            <b-form-select
              class="select-style"
              @change="onFilter"
              size="sm"
              v-model="selectedPageSize"
              :options="optionPageSize"
            >
              <template #first>
                <b-form-select-option :value="null">--Hiển thị--</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col>
            <b-form-select
              @change="onFilter"
              class="select-style"
              size="sm"
              v-model="apiParams.orderByPriority"
              :options="[
              { orderBy: 'DESC', name: 'Cao-thấp' },
              { orderBy: 'ASC', name: 'Thấp-cao' },
            ]"
              text-field="name"
              value-field="orderBy"
            >
              <template #first>
                <b-form-select-option :value="null">--Độ ưu tiên--</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col
            cols="1"
            md="1"
          >
            <b-button
              class="btn btn-success font-weight-bold"
              variant="primary"
              size="sm"
              @click="deleteFilter"
            >Làm
              mới</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="1"
            md="1"
          >
            <i class="fas fa-search text-white fa-lg"></i>
            <b-button
              class="font-weight-bold w-100 mb-5"
              variant="primary"
              size="sm"
              @click.enter="onFilter"
            >Lọc</b-button>
          </b-col>
          <b-col
            cols="1"
            md="1"
            v-show="selectedItems.length"
            v-if="checkPermission('DEALSHEET_DELETE')"
          >
            <i class="fas fa-search text-white fa-lg"></i>
            <b-button
              class="font-weight-bold w-100 mb-5"
              variant="danger"
              size="sm"
              @click="showDeleteAlert(`${modalTitle} cấu hình đã chọn`, `Bạn có chắc muốn xoá ${selectedItems.length} cấu hình đã chọn`, selectedItems, confirmDeleteDealSheet)"
            >Xoá ({{ selectedItems.length }})
            </b-button>
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <table class="table table-hover table-bordered">
          <thead>
            <tr>
              <th>
                <b-form-checkbox
                  v-model="selectAll"
                  @change="toggleSelecedAll"
                  class="b-custom-control-lg text-center"
                >
                </b-form-checkbox>
              </th>
              <th
                v-for="(field, index) in fields"
                v-bind:key="index"
                :style="field.thStyle"
              >{{ field.label }}</th>
            </tr>
          </thead>
          <tbody
            v-for="item in computedListItem"
            v-bind:key="item.id"
          >
            <tr>
              <td>
                <b-form-checkbox
                  v-model="item.checked"
                  @change="handleItemChange(item)"
                  class="b-custom-control-lg text-center"
                >
                </b-form-checkbox>
              </td>
              <td class="text-center">{{ item.id }}</td>
              <td>{{ item.code }}</td>
              <td><router-link
                  :to='"/dealsheets/info-deal-sheet?id=" + item.id'
                  tag="button"
                >{{ item.name
              }}</router-link>
              </td>
              <td class="text-right font-weight-bold">{{ item.targetMin }}%</td>
              <td class="text-right font-weight-bold">{{ item.targetMax }}%</td>
              <td class="text-right">{{ item.startDate }}</td>
              <td class="text-right">{{ item.endDate }}</td>
              <td>
                <span :class="item.isActive === 'Hoạt động'
                  ? 'badge badge-success'
                  : 'badge badge-warning'
                  ">
                  {{ item.isActive }}
                </span>
              </td>
              <!-- <td>
                <span
                  :class="item.achievementRatio >= item.targetMin
                  ? 'text-success'
                  : 'text-danger'
                  "
                  class="font-weight-bold"
                >
                  {{ item.achievementRatio || 0 }}%
                </span>
              </td> -->
              <td>{{ item.createdByName }}</td>
              <td>
                <div class="d-flex justify-content-center">
                  <b-dropdown
                    size="sm"
                    id="dropdown-left"
                    no-caret
                    right
                  >
                    <template slot="button-content">
                      <i class="flaticon2-settings"></i>
                    </template>
                    <b-dropdown-item
                      v-if="checkPermission('DEALSHEET_UPDATE')"
                      @click="editItem(item)"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem"
                          class="flaticon2-pen"
                        ></i>
                        &nbsp; Chỉnh sửa
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="checkPermission('DEALSHEET_DELETE')"
                      @click="showDeleteAlert(modalTitle, `${modalDesc} ${item.code}`, item, confirmDeleteDealSheet)"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon2-rubbish-bin-delete-button"
                        ></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <b-row>
          <b-col>
            <p
              class="mt-3 text-dark"
              style="font-weight: 500"
            >
              Tổng số:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="custom-pagination"
              v-show="numberOfPage >= 2"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="fetchData()"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
    <DealSheetImportExcel
      :dealshetIdDel="dealshetDeleted"
      @onRefresh="fetchData"
      @deleteAlert="deleteDealsheet"
    />
    <DealSheetExportExcel :apiParams="apiParams" />
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store//modules/breadcrumbs.module';
import { showDeleteAlert } from '@/utils/sweet-alert2';
import ApiService from '@/core/services/api.service';
import localData from '@/utils/saveDataToLocal';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import timeUtils from '@/utils/date';
import debounce from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';
import DealSheetExportExcel from '@/view/components/dealsheet/DealSheetExportExcel';
import DealSheetImportExcel from '@/view/components/dealsheet/DealSheetImportExcel';
import { DEALSHEET_TYPE_NAME, DEALSHEET_STATUS_NAME } from '@/utils/enum-name';
import { formatDate } from '@/utils/date';
import { DATE_FORMAT } from '@/utils/constants';
export default {
  data() {
    return {
      modalDesc: 'Bạn có chắc xoá dealsheet mã',
      modalTitle: 'Xoá ',
      isFirstLoad: false,
      dealshetDeleted: '',
      selectedItems: [],
      listItemExpire: [],
      listItemSoft: [],
      selectAll: false,
      title: '',
      apiParams: {
        searchText: null,
        startDate: null,
        endDate: null,
        isActive: null,
        type: null,
        orderByPriority: null,
      },
      listItem: [],
      onLoading: false,
      numberOfPage: 1,
      totalItems: 0,
      currentPageExpire: 1,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      selectedPageSize: null,
      limit: 10,
      optionPageSize: [10, 25, 50, 100],
      fieldExpire: [
        {
          key: 'index',
          label: 'STT',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-center',
        },
        {
          key: 'dealsheetId',
          label: 'ID',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'code',
          label: 'Mã',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'startDate',
          label: 'Ngày bắt đầu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'endDate',
          label: 'Ngày kết thúc',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'targetMin',
          label: 'ĐK min',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
        },
        {
          key: 'alias',
          label: 'Alias',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
        },
        {
          key: 'achievedRatioOfAlias',
          label: 'Thực đạt',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
        },
        {
          key: 'priority',
          label: 'Độ ưu tiên',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
      ],
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
            textAlign: 'center',
          },
        },
        {
          key: 'code',
          label: 'Mã',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '10%',
            textAlign: 'center',
          },
        },
        {
          key: 'name',
          label: 'Tên chương trình',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '25%',
            textAlign: 'center',
          },
        },
        {
          key: 'targetMin',
          label: 'ĐK Min',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
            textAlign: 'center',
          },
        },
        {
          key: 'targetMax',
          label: 'ĐK Max',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
            textAlign: 'center',
          },
        },
        {
          key: 'startDate',
          label: 'Ngày bắt đầu',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '10%',
            textAlign: 'center',
          },
        },
        {
          key: 'endDate',
          label: 'Ngày kết thúc',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '10%',
            textAlign: 'center',
          },
        },
        {
          key: 'isActive',
          label: 'Trạng thái',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '7%',
            textAlign: 'center',
          },
        },
        {
          key: 'createdByName',
          label: 'Người tạo',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '10%',
            textAlign: 'center',
          },
        },
        { key: 'actions', label: '', width: '10%' },
      ],
      dpConfigs: timeUtils.DP_CONFIG,
      listStatus: [
        {
          id: 1,
          name: 'Hoạt động',
        },
        {
          id: 0,
          name: 'Đã ngừng',
        },
      ],
    };
  },
  components: {
    KTCodePreview,
    Loader,
    DealSheetImportExcel,
    DealSheetExportExcel,
  },
  mounted() {
    this.title = 'Danh sách Dealsheet';
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  watch: {
    selectedPageSize: function (newOption) {
      this.limit = newOption;
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
    this.isFirstLoad = sessionStorage.getItem('isFirstLoad') || false;
    if (!this.isFirstLoad) {
      this.getAllDealSheetExpire();
      sessionStorage.setItem('isFirstLoad', true);
    }
  },
  methods: {
    showDeleteAlert,
    formatDate,
    showModal() {
      this.$refs['modal-scrollable'].show();
    },
    hideModal() {
      this.$refs['modal-scrollable'].hide();
    },
    checkPriority(priority) {
      if (priority && Number(priority) <= 3) {
        return 'cao';
      }
      if (priority && Number(priority) <= 7) {
        return 'Trung bình';
      }
      if (priority && Number(priority) <= 10) {
        return 'Thấp';
      }
      return priority;
    },
    toggleSelecedAll() {
      for (let i = 0; i < this.computedListItem.length; i++) {
        this.listItem[i].checked = this.selectAll;
        this.updateSelectedItems();
      }
    },
    handleItemChange(item) {
      if (!item.checked) {
        this.selectAll = false;
      }
      this.updateSelectedItems();
    },
    updateSelectedItems() {
      this.selectedItems = this.computedListItem
        .filter((item) => item.checked)
        .map((item) => item.id);
      if (this.selectedItems.length === this.computedListItem.length) {
        this.selectAll = true;
      }
    },
    downloadExcel() {
      this.$bvModal.show('modal-dealsheet-excel');
    },
    deleteDealsheet(data) {
      const { code } = data;
      showDeleteAlert(
        `${this.modalTitle} cấu hình trong hệ thống`,
        `${this.modalDesc} ${code}`,
        data,
        this.confirmDeleteDealSheet,
      );
    },
    importExcel() {
      this.$bvModal.show('modal-deal-sheet-import');
    },
    deleteFilter: function () {
      this.apiParams = {};
      this.apiParams.isActive = null;
      this.selectedPageSize = null;
      this.apiParams.orderByPriority = null;
      this.fetchData();
    },
    editItem: function (item) {
      this.$router.push({
        name: 'info-deal-sheet',
        query: { id: item.id },
      });
    },
    filterDebounce: debounce(function () {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'deal-sheets',
      });
      this.fetchData();
    }, TIME_TRIGGER),
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'deal-sheets',
      });
      this.fetchData();
    },
    getParamFilters() {
      return {
        page: this.$route.query.page || 1,
        limit: this.limit,
        searchText: this.apiParams.searchText
          ? this.apiParams.searchText.trim()
          : null,
        startDate: this.apiParams.startDate || null,
        endDate: this.apiParams.endDate || null,
        isActive: this.apiParams.isActive,
        orderByPriority: this.apiParams.orderByPriority,
      };
    },
    getAllDealSheetExpire: async function () {
      try {
        const dealsheetExpires = await ApiService.get('v2/deal-sheets-expire');
        this.listItemExpire = dealsheetExpires.data.data;
        this.listItemExpire = this.listItemExpire.map((item,index)=>({...item,index: ++index}))
        if (this.listItemExpire.length) {
          this.showModal();
        }
      } catch (err) {
        const message = err.response?.data;
        makeToastFaile(message);
      }
    },
    fetchData: async function () {
      this.selectedItems = [];
      this.selectAll = false;
      this.page = this.$route.query.page || 1;
      const params = this.getParamFilters();
      ApiService.query(`v2/deal-sheets`, { params }).then((response) => {
        const dataset = response.data.data;
        this.totalItems = dataset.totalRow;
        this.numberOfPage = dataset.totalPage || 1;
        this.listItem = dataset.dataset.map((item, index) => {
          this.page >= 2 ? index + 1 + this.page * 10 - 10 : index + 1;
          const startDate = item.startDate
            ? this.formatDate(item.startDate, DATE_FORMAT)
            : '';
          const endDate = item.endDate
            ? this.formatDate(item.endDate, DATE_FORMAT)
            : '';
          return {
            ...item,
            index: index + 1,
            type: DEALSHEET_TYPE_NAME[item.type],
            startDate,
            endDate,
            isActive: DEALSHEET_STATUS_NAME[item.isActive],
            createdByName: item.createdByName,
          };
        });
        this.onLoading = false;
      });
    },
    deleteItem: async function (id) {
      try {
        const result = await ApiService.delete('v2/deal-sheets' + '/' + id);
        const { message } = result.data;
        makeToastSuccess(message);
        this.dealshetDeleted = id;
        this.selectedItems = [];
        this.fetchData();
      } catch (err) {
        const { message } = err.response?.data;
        makeToastFaile(message);
      }
    },
    confirmDeleteDealSheet(item) {
      if (Array.isArray(item)) {
        this.deleteItem(item);
        return;
      }
      this.deleteItem(item.id);
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
    computedListItem() {
      return this.listItem;
    },
  },
};
</script>

<style lang="scss" scoped>
.dealsheet {
  .icon:hover {
    background-color: #90c6fc;
  }

  .bootstrap-datetimepicker-widget.dropdown-menu {
    width: 21em;
  }
}
</style>

<style scoped>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>